import Rellax from 'rellax';
import fetchJsonp from 'fetch-jsonp';
import 'cookieconsent/dist/cookieconsent';
import cookieConsentCss from '!file-loader!cookieconsent/dist/cookieconsent.css';

import '../styles/index.scss';

(function () {
  new Rellax('.intro__shape_1', {verticalSpeed: -2});
  new Rellax('.intro__shape_2', {verticalScrollAxis: 'x', horizontalSpeed: -2, horizontal: true});
  new Rellax('.intro__shape_3', {verticalSpeed: -4});
  new Rellax('.intro__shape_4', {verticalScrollAxis: 'x', horizontalSpeed: -1.5, horizontal: true});
  new Rellax('.intro__shape_5', {verticalScrollAxis: 'x', horizontalSpeed: 2, horizontal: true});
  new Rellax('.intro__shape_6', {verticalScrollAxis: 'x', horizontalSpeed: 3, horizontal: true});
  new Rellax('.intro__shape_7', {verticalSpeed: -0.5});

  document.getElementsByClassName('faq__title').forEach((elem) => {
    elem.addEventListener('click', function () {
      this.parentNode.parentNode.classList.toggle('faq__single--open');
    });
  });

  document.getElementsByClassName('intro__shape').forEach((elem) => {
    elem.classList.remove('intro__shape--hide');
  });

  document.querySelectorAll('a[href="#join"]').forEach((elem) => {
    elem.addEventListener('click', function (e) {
      e.preventDefault();
      const target = document.getElementById('join');
      const offset = (window.pageYOffset || document.documentElement.scrollTop);
      const scrollTo = target.getBoundingClientRect().top + offset - (window.innerHeight - target.clientHeight) / 2;
      window.scrollTo(0, scrollTo);
    });
  });

  const formElem = document.getElementById('signup-form');
  if(formElem) {
    formElem.onsubmit = function (event) {
      event.preventDefault();

      const form = document.getElementById('signup-form');
      const formData = new FormData(form);

      form.querySelector('.signup__checkbox').classList.remove('error');

      if (!formData.has('GDPR')) {
        form.querySelector('.signup__checkbox').classList.add('error');
      } else {
        fetchJsonp(`${form.action}&EMAIL=${encodeURIComponent(formData.get('EMAIL'))}`, {
          jsonpCallback: 'c',
        }).then((response) => {
          response.json().then((data) => {
            if (data.result === 'success') {
              form.remove();
              document.getElementsByClassName('signup__success')[0].style.display = 'flex';
            } else {
              alert(data.msg);
            }
          });
        });
      }
    };
  }

  document.getElementsByClassName('how-it-works__play').forEach((elem) => {
    elem.addEventListener('click', function (e) {
      elem.remove();
      document.querySelector('video').setAttribute('controls', 'controls');
      document.querySelector('video').play();
    });
  });

  const cookieconsent = initCookieConsent();
  cookieconsent.run({
    autoload_css: true,
    theme_css: cookieConsentCss,
    current_lang: 'pl',
    languages: {
      pl: {
        consent_modal: {
          title: 'Cookies',
          description: 'W celu świadczenia usług dostosowanych do naszych użytkowników stosujemy pliki cookies, które będą zamieszczone na Twoim urządzeniu końcowym. W każdym momencie możesz dokonać zmiany ustawień swojej przeglądarki internetowej i wyłączyć opcję zapisu plików cookies. Ze szczegółowymi informacjami dotyczącymi cookies stosowanymi na tej stronie przeczytasz w <a aria-label="Cookie policy" class="cc-link" href="/privacy.html" target="_blank">Polityce prywatności i wykorzystywania plików cookies</a>',
          primary_btn: {
            text: 'Akceptuję wszystkie',
            role: 'accept_all'				//'accept_selected' or 'accept_all'
          },
          secondary_btn: {
            text: 'Ustawienia',
            role: 'settings'				//'settings' or 'accept_necessary'
          }
        },
        settings_modal : {
          title : 'Ustawienia cookies',
          save_settings_btn : "Zapisz",
          accept_all_btn : "Zaakceptuj wszystkie",
          blocks : [
            {
              title : "Niezbędne cookies",
              description: 'Cookies, umożliwiające korzystanie z Portalu, np. cookies uwierzytelniające;',
              toggle : {
                value : 'necessary_cookies',
                enabled : true,
                readonly: true
              }
            },{
              title : "Cookies analyticzne",
              description: 'Cookies pozwala nam mierzyć ilość wizyt, zbierać informacje o źródłach ruchu oraz analizować zachowanie użytkowników na stronie',
              toggle : {
                value : 'analytics_cookies',
                enabled : false,
                readonly: false
              },
            },{
              title : "Cookies marketingowe",
              description: 'Cookies pozwalające na profilowanie reklam w innych serwisach internetowych',
              toggle : {
                value : 'marketing_cookies',
                enabled : false,
                readonly: false
              },
            },{
              title : "Więcej informacji",
              description: 'W celu świadczenia usług dostosowanych do naszych użytkowników stosujemy pliki cookies, które będą zamieszczone na Twoim urządzeniu końcowym. W każdym momencie możesz dokonać zmiany ustawień swojej przeglądarki internetowej i wyłączyć opcję zapisu plików cookies. Ze szczegółowymi informacjami dotyczącymi cookies stosowanymi na tej stronie przeczytasz w <a aria-label="Cookie policy" class="cc-link" href="/privacy.html" target="_blank">Polityce prywatności i wykorzystywania plików cookies</a>',
            }
          ]
        }
      }
    },
    onAccept: function(){
      if(cookieconsent.allowedCategory('analytics_cookies')){
        cookieconsent.loadScript('https://www.googletagmanager.com/gtag/js?id=G-TC76RPMFPJ', function(){
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-TC76RPMFPJ');
        });
      }

      if(cookieconsent.allowedCategory('marketing_cookies')){
        !function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)}; if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s)}(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '2228140654061828');
        fbq('track', 'PageView');
      }
    },
  });
})();
